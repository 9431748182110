import React from 'react';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useIsMobile } from '../../hooks';
import { getTimeDiffText } from '../../helpers';

const DateCell = ({ date }) => {
  const isMobile = useIsMobile();
  const dateMoment = moment(date);
  return (
    <Stack justifyContent="center" alignItems="flex-start" direction="column">
      <Typography variant="body2">
        {dateMoment.format(isMobile ? 'DD MMM' : 'DD-MM-YYYY')}
      </Typography>
      {!isMobile && <Typography variant="caption">{getTimeDiffText(dateMoment)}</Typography>}
    </Stack>
  );
};

DateCell.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]).isRequired,
};

export default DateCell;
