import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { invoiceCessionsTooltipHandler } from '../../constants/CessionsTooltip';
import { useBooleanState, useGetInvoice } from '../../hooks';
import { INVOICE_CESSIONS_TOOLTIP } from '../../graphql';

const InvoiceCessionsTooltip = ({ invoice, children }) => {
  const [open, toggleOpen] = useBooleanState();
  const cessionTooltipInvoice = useGetInvoice(open && invoice.id, INVOICE_CESSIONS_TOOLTIP);
  const companyReceiverCessionHistory = useMemo(
    () => cessionTooltipInvoice?.companyReceiverCessionHistory || {},
    [cessionTooltipInvoice],
  );
  const companyCessionHistory = useMemo(
    () => cessionTooltipInvoice?.company?.cessionsHistory || {},
    [cessionTooltipInvoice],
  );
  const receiverCessionsHistory = useMemo(
    () => cessionTooltipInvoice?.receiver?.receiverCessionsHistory || {},
    [cessionTooltipInvoice],
  );
  const text = useMemo(() => invoiceCessionsTooltipHandler(
    companyCessionHistory,
    companyReceiverCessionHistory,
    receiverCessionsHistory,
  ), [companyCessionHistory, companyReceiverCessionHistory, receiverCessionsHistory]);
  return (
    <Tooltip
      title={
        cessionTooltipInvoice ? (
          <Typography sx={{ whiteSpace: 'pre-wrap', fontSize: 11, margin: 2 }} variant="body2">
            {text}
          </Typography>
        ) : (
          <CircularProgress />
        )
      }
      placement="right"
      onOpen={toggleOpen}
      onClose={toggleOpen}
    >
      {children}
    </Tooltip>
  );
};

InvoiceCessionsTooltip.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    company: PropTypes.shape({
      cessionsHistory: PropTypes.shape({
        cessionsNumber: PropTypes.number,
        cessionsMoneyAmount: PropTypes.number,
        historicCessionsNumber: PropTypes.number,
        historicCessionsMoneyAmount: PropTypes.number,
      }),
    }),
    receiver: PropTypes.shape({
      receiverCessionsHistory: PropTypes.shape({
        cessionsNumber: PropTypes.number,
        cessionsMoneyAmount: PropTypes.number,
        historicCessionsNumber: PropTypes.number,
        historicCessionsMoneyAmount: PropTypes.number,
      }),
    }),
    companyReceiverCessionHistory: PropTypes.shape({
      cessionsNumber: PropTypes.number,
      cessionsMoneyAmount: PropTypes.number,
      historicCessionsNumber: PropTypes.number,
      historicCessionsMoneyAmount: PropTypes.number,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default InvoiceCessionsTooltip;
