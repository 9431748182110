import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const GenericTooltipCell = ({ data }) => (
  <Tooltip title={data} sx={{ overflow: 'hidden' }}>
    <Typography>{data}</Typography>
  </Tooltip>
);

GenericTooltipCell.propTypes = {
  data: PropTypes.string.isRequired,
};

export default GenericTooltipCell;
