import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const BoxCell = ({ header, addedValue, title, subtitle, loading }) => (
  <Grid
    item
    xs={12}
    lg={2.8}
    sx={{
      zIndex: 4,
      textDecoration: 'none',
    }}
  >
    <Typography color="primary" variant="h6" sx={{ marginBottom: 1 }}>
      <b>{header}</b>
    </Typography>
    <Paper sx={{ borderRadius: 4, height: '100%' }}>
      <Stack pt={3} pm={3} spacing={1}>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <Typography variant="h5" align="center" fontWeight={500}>
              {title} {addedValue}
            </Typography>
            <Typography variant="body1" align="center">
              {subtitle}
            </Typography>
          </>
        )}
      </Stack>
    </Paper>
  </Grid>
);

BoxCell.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addedValue: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};

BoxCell.defaultProps = {
  header: '',
  title: '',
  subtitle: '',
  addedValue: '',
  loading: false,
};

export default BoxCell;
