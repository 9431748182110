import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useBooleanState } from '../../hooks';
import { FingoDialog } from '../dialogs';

const LongTextCell = ({ title, text }) => {
  const [open, toggleOpen] = useBooleanState(false);
  return (
    <Grid>
      <Typography onClick={toggleOpen} sx={{ cursor: 'pointer' }}>{text}</Typography>
      <FingoDialog
        title={title}
        open={open}
        handleClose={toggleOpen}
        fullWidth
      >
        <Typography
          variant="subtitle2"
          onClick={toggleOpen}
        >
          {text}
        </Typography>
      </FingoDialog>
    </Grid>
  );
};

LongTextCell.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default LongTextCell;
