import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Upload from '@mui/icons-material/Upload';
import { openUrlAndDownload } from '../../helpers';

const DocumentChip = ({
  documentToUpload,
  inputRef,
  document,
  handleChange,
  loading,
  handleOpenInput,
  url,
}) => {
  const hasDocument = !!url;
  const isLoading = useMemo(
    () => documentToUpload?.documentType === document.documentType && loading,
    [documentToUpload, document, loading],
  );

  const handleClick = () => {
    if (document.documentType === 'OTHER_DOCUMENTS') {
      document.handleClick();
    } else if (url !== '') {
      openUrlAndDownload(url);
    }
  };

  return (
    <>
      <input type="file" hidden ref={inputRef} onChange={(e) => handleChange(e, document)} />
      <Chip
        size="small"
        label={document.label}
        id={document.documentType.toLowerCase()}
        color={hasDocument ? 'primary' : 'default'}
        variant={hasDocument ? 'filled' : 'outlined'}
        onClick={handleClick}
        onDelete={() => handleOpenInput(document)}
        deleteIcon={isLoading ? <CircularProgress size={12} /> : <Upload />}
        sx={{ '& .MuiChip-label': { fontSize: 11 }, marginInline: 0.2, marginTop: 0.5 }}
      />
    </>
  );
};

DocumentChip.propTypes = {
  documentToUpload: PropTypes.shape({
    documentType: PropTypes.string,
  }),
  document: PropTypes.shape({
    label: PropTypes.string,
    documentType: PropTypes.string,
    handleClick: PropTypes.func,
    companyDocument: PropTypes.shape({
      id: PropTypes.string.isRequired,
      globalAppId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
  handleOpenInput: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.object,
  url: PropTypes.string,
};

DocumentChip.defaultProps = {
  url: null,
  documentToUpload: { documentType: '' },
  handleChange: () => {},
  handleOpenInput: () => {},
  loading: false,
  inputRef: null,
};

export default DocumentChip;
