import React from 'react';
import PropTypes from 'prop-types';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const BooleanCell = ({ value }) => {
  if (value === null) {
    return <HorizontalRuleIcon fontSize="small" />;
  }
  return value ? (
    <CheckCircleRoundedIcon color="success" fontSize="small" />
  ) : (
    <CancelRoundedIcon color="error" fontSize="small" />
  );
};

BooleanCell.propTypes = {
  value: PropTypes.bool,
};

BooleanCell.defaultProps = {
  value: null,
};

export default BooleanCell;
