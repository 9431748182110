import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import HelpRounded from '@mui/icons-material/HelpRounded';
import { formatMoney } from '../../helpers';

const retrieveInvoicesRelatedTooltip = (invoices) => {
  if (!invoices.length) return 'No hay Facturas Relacionadas';
  let tooltip = 'Facturas Relacionadas';
  for (let i = 0; i < invoices.length; i += 1) {
    tooltip = tooltip.concat(
      `\n
      Folio : ${invoices[i].folio}
      Monto c/ IVA: $${formatMoney(invoices[i].amountWithIva)}
      Empresa: ${invoices[i].company.name}
      Rut: ${invoices[i].company.rut}
      `,
    );
  }
  return tooltip;
};

const InvoiceLoansRelatedCell = ({ row }) => {
  const invoicesRelated = row.invoiceLoanManagers?.map(
    (moneyTransfer) => moneyTransfer.document,
  ) ?? [];
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography>{row.id}</Typography>
      <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{retrieveInvoicesRelatedTooltip(invoicesRelated)}</span>}>
        <HelpRounded sx={{ width: 20 }} />
      </Tooltip>
    </Stack>
  );
};

InvoiceLoansRelatedCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invoiceLoanManagers: PropTypes.arrayOf(
      PropTypes.shape({
        document: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};

export default InvoiceLoansRelatedCell;
