import React from 'react';
import { InvoiceType } from '../../propTypes';
import { useGetCountryFromUrl } from '../../hooks';
import ChileCommercialAlertsArray from './commercial-alerts-array/ChileCommercialAlertsArray';
import MexicoCommercialAlertsArray from './commercial-alerts-array/MexicoCommercialAlertsArray';

const CommercialAlertsArray = ({ invoice }) => {
  const country = useGetCountryFromUrl();
  const countryComponentMapper = {
    Chile: <ChileCommercialAlertsArray invoice={invoice} />,
    Mexico: <MexicoCommercialAlertsArray invoice={invoice} />,
  };
  return country?.name && countryComponentMapper[country.name];
};

CommercialAlertsArray.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default CommercialAlertsArray;
