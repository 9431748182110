import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ThumbUp from '@mui/icons-material/ThumbUp';
import { green } from '@mui/material/colors';
import SiiIcon from '../icons/SiiIcon';

const buildHasCredentialsArray = (company) => [
  {
    isOk: company.hasLendingAcceptedterms,
    label: 'Términos y condiciones',
    icon: <ThumbUp sx={{ fontSize: 12 }} />,
  },
  {
    isOk: company.hasSiiCredentials,
    label: 'Credenciales SII',
    icon: <SiiIcon sx={{ color: 'primary', fontSize: 12 }} />,
  },
  {
    isOk: company.hasInvoiceProviderCredentials,
    label: 'Credenciales Facturador',
    icon: <Typography sx={{ fontSize: 12 }}>F</Typography>,
  },
  {
    isOk: company.hasDigitalCertificate,
    label: 'Certificado digital',
    icon: <Typography sx={{ fontSize: 9 }}>Cert</Typography>,
  },
  {
    isOk: company.hasSupplierPortalCredentials,
    label: 'Portal de proveedores',
    icon: <Typography sx={{ fontSize: 12 }}>P</Typography>,
  },
  {
    isOk: company.hasFrameworkContract,
    label: 'Contrato Marco',
    icon: <Typography sx={{ fontSize: 12 }}>M</Typography>,
  },
];

const CompanyHasCredentialsCell = ({ company, ...props }) => {
  const hasCredentialsArray = buildHasCredentialsArray(company);
  return (
    <Grid container nowrap="true" {...props}>
      {hasCredentialsArray.map((credentialData) => (
        <Tooltip
          arrow
          key={credentialData.label.toLowerCase()}
          title={
            credentialData.isOk ? `Con ${credentialData.label}` : `Sin ${credentialData.label}`
          }
        >
          <Avatar
            sx={{
              width: 22,
              height: 22,
              bgcolor: credentialData.isOk ? green[700] : '',
              mx: 0.1,
            }}
          >
            {credentialData.icon}
          </Avatar>
        </Tooltip>
      ))}
    </Grid>
  );
};

CompanyHasCredentialsCell.propTypes = {
  company: PropTypes.shape({
    hasLendingAcceptedterms: PropTypes.bool.isRequired,
    hasSiiCredentials: PropTypes.bool,
    hasInvoiceProviderCredentials: PropTypes.bool,
    hasDigitalCertificate: PropTypes.bool.isRequired,
    hasSupplierPortalCredentials: PropTypes.bool,
    hasFrameworkContract: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CompanyHasCredentialsCell;
