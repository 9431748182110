import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

const LastActionDate = ({ actions }) => {
  const lastAction = actions.length > 0 ? (
    actions.reduce(
      (prevAct, currAct) => (prevAct.createdAt > currAct.createdAt ? prevAct : currAct),
    )
  ) : (null);
  return (
    <Stack alignItems="center">
      <Typography variant="body1" fontSize={9} noWrap>
        {lastAction ? moment(lastAction.createdAt).format('DD-MM-YYYY') : ''}
      </Typography>
      <Typography variant="body1" fontSize={9} noWrap>
        {lastAction ? moment(lastAction.createdAt).format('HH:mm[hrs]') : ''}
      </Typography>
    </Stack>
  );
};

LastActionDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array.isRequired,
};

export default LastActionDate;
