import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import useGetCountryFromUrl from '../../hooks/useGetCountryFromUrl';
import { HISTORIC_DOCUMENTS } from '../../constants/documents';
import { buildCompanyDocumentsArray } from '../../helpers/documents';
import useUploadCompanyDocument from '../../hooks/useUploadCompanyDocument';

const CustomerDocumentsCell = ({
  masterEntity,
  showGenerateContract,
  year,
  wrap,
  initialDocumentTypes,
}) => {
  const country = useGetCountryFromUrl();
  const countryDocumentTypes = country?.documentTypes;
  const { company } = masterEntity;

  let documents;
  let documentTypes = initialDocumentTypes ? countryDocumentTypes.filter(
    (type) => initialDocumentTypes.includes(type.code),
  ) : countryDocumentTypes;

  if (year) {
    documents = company.documents.filter(
      (doc) => doc.documentDate.slice(0, 4) === year,
    );
    // if not current year
    if (year !== new Date().getFullYear().toString()) {
      documentTypes = countryDocumentTypes.filter((type) => HISTORIC_DOCUMENTS.includes(type.code));
    }
  } else {
    const documentsCopy = [...company.documents];
    documents = documentsCopy.sort((a, b) => {
      const dateA = new Date(a.documentDate);
      const dateB = new Date(b.documentDate);
      return dateB - dateA;
    });
  }
  const documentsArray = buildCompanyDocumentsArray(
    documents,
    documentTypes,
  ).filter((document) => {
    if (
      !showGenerateContract
      && document.documentType === 'generated_contract'
    ) {
      return false;
    }
    return true;
  });

  const inputRef = useRef(null);
  const [documentToUpload, setDocumentToUpload] = useState({});
  const { submit, loading } = useUploadCompanyDocument();

  const handleOpenInput = (document) => {
    setDocumentToUpload(document);
    inputRef.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    submit({
      documentType: documentToUpload.documentType,
      file,
      companyId: company.id,
      year,
    });
    setDocumentToUpload({});
    inputRef.current.value = '';
  };

  return (
    <Stack direction="row" flexWrap={wrap ? 'wrap' : 'nowrap'}>
      {documentsArray.map((document) => (
        <document.component
          key={`${document.documentType.toLowerCase()}-item`}
          document={document}
          companyId={company.id}
          documentToUpload={documentToUpload}
          inputRef={inputRef}
          handleChange={handleChange}
          loading={loading}
          handleOpenInput={handleOpenInput}
          url={document.url}
        />
      ))}
    </Stack>
  );
};

CustomerDocumentsCell.propTypes = {
  showGenerateContract: PropTypes.bool,
  masterEntity: PropTypes.shape({
    country: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      documentTypes: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        shortName: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          globalAppId: PropTypes.string.isRequired,
          lastFile: PropTypes.string,
          documentType: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  year: PropTypes.string,
  wrap: PropTypes.bool,
  initialDocumentTypes: PropTypes.arrayOf(PropTypes.string),
};

CustomerDocumentsCell.defaultProps = {
  showGenerateContract: false,
  year: null,
  wrap: false,
  initialDocumentTypes: null,
};

export default CustomerDocumentsCell;
